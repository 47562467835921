<template>
<el-dialog v-model="visible" title="市场专员详情" :before-close="close">
  <div class="dialog-body market-partner-detail" v-loading="loading">
    <div class="mpd-title">基本信息</div>
    <table class="mpd-base-info-table my-table">
      <tr>
        <th>姓名</th>
        <td>{{compData.txt_realname}}</td>
        <th>预留手机号</th>
        <td>{{compData.txt_mobile||"- -"}}</td>
      </tr>
      <tr>
        <th>上级代理</th>
        <td>{{compData.txt_pname}}</td>
        <th>申请日期</th>
        <td>{{compData.dt_apply||"- -"}}</td>
      </tr>
      <tr>
        <th>申请地区</th>
        <td colspan="3">{{compData.txt_area_name}}</td>
      </tr>
      <tr>
        <th>身份证号</th>
        <td colspan="3">{{compData.identityCardNo}}</td>
      </tr>
      <tr>
        <th>银行卡号</th>
        <td colspan="3">{{compData.bankCardNo}}</td>
      </tr>
      <tr>
        <th>开户行信息</th>
        <td colspan="3">{{compData.bankName||"- -"}}</td>
      </tr>
      <tr>
        <th>微信登录地址</th>
        <td colspan="3">{{compData.txt_wechat_address||"- -"}}</td>
      </tr>
      <tr>
        <th>微信登录坐标</th>
        <td colspan="3">{{compData.txt_wechat_coordinate||"- -"}}</td>
      </tr>
      <tr>
        <th>审核状态</th>
        <td>
          <template v-if="compData.txt_verify_status==1">已通过</template>
          <template v-else-if="compData.txt_verify_status==2">未通过</template>
        </td>
        <th>审核时间</th>
        <td>{{compData.dt_verify||"- -"}}</td>
      </tr>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 市场专员详情
import { onMounted, reactive, toRefs } from 'vue';
import { ElMessage } from 'element-plus';
import { getAgentDetail } from "api/apis.js";
export default {
  props: { detailData: { type: Object } },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      compData: {}
    });

    const close = () => {
      ctx.emit("close");
    };

    // 获取详情
    const getContractList = () => {
      state.loading = true;
      getAgentDetail({
        agent_id: prop.detailData.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          state.compData = response.data;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    onMounted(() => {
      getContractList();
    })

    return {
      ...toRefs(state),
      close,
      getContractList
    }
  }
}
</script>

<style lang="scss" scoped>
.market-partner-detail {
  .mpd-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
